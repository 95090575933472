@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  background-color: #2f3136;
}

body,html {
  margin: 0;
  padding: 0;
}

*{
  box-sizing: border-box;
}

body {  
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .firebaseui-idp-text{
    display: none !important;
  }

  .mdl-button{
    min-width: auto !important;
  }
}

#firebaseui-auth-container button{
  background-color: #2f3136 !important;
  border-radius: 5px !important;
}
#firebaseui-auth-container span{
  color: #d4d7dc !important;
  white-space: nowrap !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border-red-500{
  border: 1px solid #f04747 !important;
}

:root::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

:root::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #313339;
}

:root::-webkit-scrollbar-thumb {
  background: #202225;
  border-radius: 100vh;
}

:root::-webkit-scrollbar-corner {
  background-color: #2f3136;
}

.animation-scale-in {
  animation: scale-in 0.15s ease-in;
}

@keyframes scale-in {
  from { transform: scale(0.8); }
  to { transform: scale(1); }
}

@layer utilities {
  .scrollbar2 {
    padding-bottom: 0.3rem;
  }

  .scrollbar2::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  
  .scrollbar2::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #232529;
  }
  
  .scrollbar2::-webkit-scrollbar-thumb {
    background: #313339;
    border-radius: 100vh;
  }

  .scrollbar2::-webkit-scrollbar-corner {
    background-color: #232529;
  }

  .scrollbar::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #313339;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background: #232529;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-corner {
    background-color: #313339;
  }
}